<template>
	<div>
		<a-form-model labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol">
			<a-form-model-item label="选择收件人">
				<select-staff :value="formData.receiveIds" :names="receiveNames" multiple
					@input="e => (formData.receiveIds = e)"></select-staff>
			</a-form-model-item>
			<a-row>
				<a-col push="2">
					<a href="javascript:;" @click="iscopy = !iscopy">{{ iscopy ? '删除抄送' : '添加抄送' }}</a>
				</a-col>
			</a-row>

			<a-form-model-item v-if="iscopy" label="添加抄送">
				<select-staff :value="formData.copyIds" multiple @input="e => (formData.copyIds = e)"></select-staff>
			</a-form-model-item>
			<a-form-model-item label="主题">
				<a-input placeholder="请输入主题" v-model="formData.title"></a-input>
			</a-form-model-item>

			<a-form-item label="正文">
				<editor v-model="formData.content" @input="e => (formData.content = e)"></editor>
			</a-form-item>
			<a-row>
				<a-col push="2">
					<a-form-item>
						<attachUpload :value="formData.attachment" @input="e => (formData.attachment = e)">
						</attachUpload>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col push="2">
					<a-button style="margin-right:20px" type="primary" @click="onSubmit">直接发送</a-button>
					<a-button type="primary" @click="ondraft">存为草稿</a-button>
					<a-button style="margin-left:20px" @click="$emit('success')">返回</a-button>
				</a-col>
			</a-row>
		</a-form-model>
	</div>
</template>

<script>
import editor from '@/components/component/editor.vue';

import selectStaff from '@/components/common/select-staff.vue';
import apiUtil from '@/common/apiUtil';
import attachView from '@/components/common/attach-view.vue';
import attachUpload from '@/components/common/attach-upload.vue';
export default {
	components: { editor, selectStaff, attachUpload, attachView },
	data() {
		return {
			visible: false,
			confirmLoading: false,
			iscopy: false,
			labelCol: { span: 2 },
			wrapperCol: { span: 18 },
			receiveNames: [],
			fileList: [],
			formData: {
				receiveIds: [],
				copyIds: [],
				title: '',
				content: '',
				attachment: []
			}
		};
	},
	mounted() {
		if (this.rowMail) {
			// console.log(this.rowMail);
			this.receiveNames = this.rowMail.receiveNames;
			this.formData.receiveIds = this.rowMail.receiveIds;
			this.formData.copyIds = (this.rowMail.copyIds == '' ? [] : this.rowMail.copyIds);
			this.formData.title = this.rowMail.title;
			this.formData.content = this.rowMail.content;
			this.formData.id = this.rowMail.id;
			this.formData.attachment = (this.rowMail.attachment == '' ? [] : this.rowMail.attachment)
		}
	},

	props: ['rowMail'],
	methods: {
		// 发送请求
		postmail(type) {
			this.http
				.post(`/platform/mail/send/${type}`, this.formData)
				.then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.$emit('success');
						});
					}
				})
				.catch(err => { });
		},
		// 直接发送
		onSubmit() {
			// console.log(this.formData.attachment);
			this.postmail('issue');
		},
		//保存为草稿
		ondraft() {
			this.postmail('draft');
		},

		// 点击添加附件
		addfile() {
			this.visible = true;
		},

		handleCancel() {
			this.visible = false;
		},
		// 确定
		handleOk() {
			this.visible = false;
		},
		// 返回取消
		handleCancel(e) {
			console.log('Clicked cancel button');
			this.visible = false;
		}
	}
};
</script>

<style></style>
